import bookingSlotRequest from '../../requests/booking-slot-request';
import pageRequest from '../../requests/page-request';
import { getPageDetailAction, updateOrCreateBookingSlotAction } from './actions';

export const getPageDetail = (slug, data) => {
    return dispatch => {
        return pageRequest
            .find(slug, data)
            .then(res => {
                dispatch(getPageDetailAction(res));
            })
            .catch(error => {
                console.log(error);
            });
    };
};

export const updateOrCreateBookingSlot = options => {
    const { data, dayIndex, index, key } = options;
    return dispatch => {
        return bookingSlotRequest
            .updateOrAddBookingSlot(data, key)
            .then(res => {
                dispatch(updateOrCreateBookingSlotAction(res, dayIndex, index));
            })
            .catch(err => {
                if (err.response) {
                    console.error(err.response.data);
                }
            });
    };
};
