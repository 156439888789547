export const LEAD_STATUSES = {
    online_process: 'online_process',
    online_failed_test: 'online_failed_test'
};

export const LEAD_SOURCES = {
    youcanbookme: 'Youcanbookme',
    booking_system: 'BookingSystem',
    website: 'website',
    email: 'Email',
    call: 'Call',
    affiliate: 'Affiliate',
    other: 'Other'
};

export const ELIGIBILITY = {
    FAILED_US_CITIZEN: 'failed_UScitizen',
    FAILED_DIPLOMA: 'failed_Diploma',
    ELIGIBILITY_CHECK_PASSED: 'eligibility_check_passed',
    ELIGIBILITY_FAILED: 'eligibility_failed',
    ELIGIBILITY_PASSED: 'eligibility_passed',
    PASSED: 'passed',
    FAILED: 'failed',
    FAILED_TEST: 'failed_test',
    PASSED_TEST: 'passed_test'
};
