import React from 'react';

import LocationList from '../components/LocationList';
import { useQuery } from '../hooks/useQuery';

export default function SelectLocation() {
    const searchQueries = useQuery();
    const referral = searchQueries.get('referral');

    const pagesA = [
        { title: 'オンラインで実施する', slug: 'session-online-jp' },
        { title: 'テックアイエス 愛媛校に行く', slug: 'session-ehime-jp' },
        { title: 'テックアイエス 名古屋校に行く', slug: 'session-aichi-jp' },
        { title: 'テックアイエス 島根校に行く', slug: 'session-shimane-jp' },
        { title: 'テックアイエス 岡山校に行く', slug: 'session-okayama-jp' }
    ];

    return (
        <div className="select-location mt-0">
            {pagesA && pagesA.map((page, index) => <LocationList key={index} page={page} referral={referral} />)}
        </div>
    );
}
