import 'react-phone-input-2/lib/bootstrap.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

export default function CustomPhoneNumber(props) {
    const { t } = useTranslation();
    const { label, required, invalid, onChange, invalidText, country, value, customStyle } = props;

    return (
        <>
            <Form.Group className={customStyle ? customStyle : 'mb-3'}>
                {!!label &&<><Form.Label>{label}</Form.Label>{' '}</>}
                {required ? <span className="text-muted">({t('Required')})</span> : null}
                <PhoneInput
                    country={country === 'usa' ? 'us' : country}
                    onChange={(phone, country) => onChange(`+${phone}`, country)}
                    isValid={!invalid}
                    preferredCountries={['us', 'jp']}
                    inputStyle={{
                        width: '100%',
                        // height: '55px'
                        paddingTop: '14px',
                        paddingBottom: '14px'
                    }}
                    inputProps={{
                        required: { required },
                        name: 'phone'
                    }}
                    value={value}
                />
                <Form.Control.Feedback type="invalid" className={invalid && 'd-block'}>
                    {invalidText}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    );
}

CustomPhoneNumber.defaultProps = {
    placeholder: 'Phone Number'
};

CustomPhoneNumber.propTypes = {
    placeholder: PropTypes.string
};
