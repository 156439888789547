import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import BookingDateTime from '../components/BookingDateTime';
import CustomLoading from '../components/Custom/CustomLoading';
// import CustomModal from '../components/Custom/CustomModal';
import CustomNotFound from '../components/Custom/CustomNotFound';
import TimezonePicker from '../components/TimezonePicker';
import { getPageDetailAction, getPageScheduleAction } from '../reducks/pages/actions';
import { getPage } from '../reducks/pages/selectors';
import pageRequest from '../requests/page-request';
import { getCurrentTimezone, getDefaultTimezone, getFullTimeZone } from '../utils/common';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const selector = useSelector(state => state);
    const page = getPage(selector);
    const [defaultTimezone, setDefaultTimezone] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const { pageSlug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const dateInfo = page.dateInfo;
    const location = useLocation();
    const { redirect_to } = location.state || {};
    const monthTranslations = {
        January: '1月',
        February: '2月',
        March: '3月',
        April: '4月',
        May: '5月',
        June: '6月',
        July: '7月',
        August: '8月',
        September: '9月',
        October: '10月',
        November: '11月',
        December: '12月'
    };

    useEffect(() => {
        setIsLoading(true);
        pageRequest
            .find(pageSlug)
            .then(res => {
                dispatch(getPageDetailAction(res));
                setIsLoading(false);
            })
            .catch(error => {
                setIsError(true);
                console.log(error);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsLoading(true);
        if (defaultTimezone) {
            pageRequest
                .getPageSchedule(pageSlug, { timezone: getFullTimeZone(defaultTimezone) })
                .then(res => {
                    dispatch(getPageScheduleAction(res));
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsError(true);
                    console.log(error);
                });
        }

        // eslint-disable-next-line
    }, [defaultTimezone]);

    useEffect(() => {
        const defaultTimezone = getDefaultTimezone(page.data ? page.data.country : null);
        timezone ? setDefaultTimezone(timezone) : setDefaultTimezone(defaultTimezone.key);
        i18n.changeLanguage(page.data ? page.data.language : 'en');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone, page]);

    // Check if dateInfo exists before destructuring its properties
    const startDate =
        dateInfo && dateInfo.startMonth && dateInfo.startYear
            ? `${dateInfo.startYear}年${monthTranslations[dateInfo.startMonth]}`
            : '';
    const endDate =
        dateInfo && dateInfo.endMonth && dateInfo.endYear
            ? `${dateInfo.endYear}年${monthTranslations[dateInfo.endMonth]}`
            : '';

    return (
        <>
            {/* <CustomModal isOpen={getCurrentTimezone() === 'IST'} /> */}
            <div className={`header-container ${i18n.language === 'jp' ? 'mt-0 mb-0' : ''}`}>
                <div className={`timezone ${i18n.language === 'jp' ? 'hide' : ''}`}>
                    <TimezonePicker
                        defaultValue={defaultTimezone}
                        onChange={e => {
                            setTimezone(e.target.value);
                        }}
                    />
                </div>

                {dateInfo && i18n.language !== 'jp' ? (
                    <div className={`date-info`}>
                        <span>{`${dateInfo.startMonth} ${
                            dateInfo.startYear !== dateInfo.endYear ? dateInfo.startYear : ''
                        } ${dateInfo.startMonth !== dateInfo.endMonth ? '- ' + dateInfo.endMonth : ''} ${
                            dateInfo.endYear
                        }`}</span>
                    </div>
                ) : null}

                {i18n.language === 'jp' && dateInfo ? (
                    <div className={`date-info pt-2 pb-2`}>
                        <span>{`${startDate}${startDate !== endDate ? ' - ' + endDate : ''}`}</span>
                    </div>
                ) : null}
            </div>

            <div className={`schedule justify-content-center ${i18n.language === 'jp' ? 'mt-0' : ''}`}>
                {isError ? (
                    <CustomNotFound message="Page not found." />
                ) : isLoading ? (
                    <CustomLoading />
                ) : page && page.schedules ? (
                    page.schedules.map((s, index) => {
                        return (
                            <BookingDateTime
                                key={s.date}
                                date={s.date}
                                times={s.times}
                                isToday={index === 0}
                                isAvailable={s.is_available_day}
                                timezone={defaultTimezone}
                                redirect_to={redirect_to ?? 'booking'}
                            />
                        );
                    })
                ) : null}
            </div>
        </>
    );
};

export default Home;
