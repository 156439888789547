import { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { SelectDatepicker } from 'react-select-datepicker';

//json
import { questions } from './json/eligibilityTestJson';
import EligibilityTest from './EligibilityTest';
import { LEAD_STATUSES } from '../../utils/constant';
import { eligibilityCheck } from './json/eligibilityCheck';
// component
import EligibilityProgress from './EligibilityProgress';
import CustomTextField from '../../components/Custom/CustomTextField';
import CustomPhoneNumber from '../../components/Custom/CustomPhoneNumber';
import CustomLoadingButton from '../../components/Custom/CustomLoadingButton';
// function
import { getLocalStorageLog, setLocalStorageLog } from '../../utils/common';
import eligibilityRequest from '../../requests/eligibility-request';
import { useQuery } from '../../hooks/useQuery';

export default function EligibilityUserInfo({ handleQuestionSubmit, handleHeader, handleUserTest }) {
    const [stateLog, setStateLog] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [optionError, setOptionError] = useState(false);
    const [change, setChange] = useState(false);
    const searchQueries = useQuery();
    const [user, setUserInfo] = useState({
        name: '',
        email: '',
        phone: '',
        nationality: '',
        birthday: null,
        source: 'booking_system',
        academic_history: '',
        status: LEAD_STATUSES.online_process,
        referral: searchQueries.get('referral')
    });
    const selectDateOptions = {
        monthPlaceholder: 'Month',
        dayPlaceholder: 'Day',
        yearPlaceholder: 'Year'
    };
    const dataValue = JSON.parse(getLocalStorageLog());

    useEffect(() => {
        if (stateLog != null) {
            setLocalStorageLog(stateLog);
        }
        // eslint-disable-next-line
    }, [stateLog]);

    useEffect(() => {
        handleHeader('Logical + Aptitude Test');
        // eslint-disable-next-line
    }, [questionIndex]);

    useEffect(() => {
        const dataValue = JSON.parse(getLocalStorageLog());
        if (dataValue?.user_info) {
            setUserInfo(dataValue?.user_info);
        }
        // eslint-disable-next-line
    }, []);

    function handleChange(evt) {
        const { name, value } = evt.target;
        setUserInfo(prev => ({
            ...prev,
            [name]: value
        }));
    }

    function handlePhoneChange(value, country) {
        setUserInfo(prev => ({
            ...prev,
            phone: value,
            nationality: country.name
        }));
    }

    function onDateChange(e) {
        setUserInfo(prev => ({
            ...prev,
            birthday: e
        }));
    }

    async function handleSubmit(evt) {
        evt.preventDefault();
        const dataValue = JSON.parse(getLocalStorageLog());
        setOptionError(false);

        if (questionIndex < questions.length + 1) {
            //compare current user to local storage so we dont have to call api multiple time if user click prev or next after submit user_info
            if (!compareUserObject(user, dataValue?.user_info)) {
                //check if phone number is valid
                if (user.phone && !isValidPhoneNumber(user.phone)) {
                    setErrors(prev => {
                        return { ...prev, phone: [`Invalid Phone Number.`] };
                    });
                    return;
                }

                //start quiz
                try {
                    //transform user info from number to text
                    setIsLoading(true);
                    let green_card = +dataValue?.section_1[1] === 4 || +dataValue?.section_1[1] === 3;
                    let academic_history = eligibilityCheck[1].options[dataValue?.section_1[2] - 1];

                    if (academic_history.value === 1) {
                        // backend doesn't have option for Master degree or more
                        academic_history = 'graduate_degree_masters';
                    } else {
                        academic_history = academic_history.name.toLocaleLowerCase().replace(/\s/g, '_');
                    }

                    const temp = {
                        ...user,
                        has_green_card: !green_card,
                        academic_history: academic_history,
                        birthday: user?.birthday ? new Date(user.birthday).toISOString().split('T')[0] : ''
                    };
                    //await for result to get session_token
                    const result = await eligibilityRequest.store(temp);

                    if (result?.data?.session_token) {
                        setStateLog(dataValue);
                        setStateLog(prev => ({
                            ...prev,
                            user_info: user,
                            start_test: new Date(),
                            session_token: result?.data?.session_token
                        }));
                        setIsLoading(false);
                        //start the first test question
                        setQuestionIndex(prev => prev + 1);
                        setErrors([]);
                        //calculate time for the test and remove localstorage
                        handleUserTest();
                    }
                } catch (e) {
                    try {
                        let temp = e.response.data.error;
                        setErrors(temp);
                        setIsLoading(false);
                    } catch (e) {
                        console.log(e);
                    }
                }
            } else {
                //first initial of the test
                handleUserTest();
                if (checkOptions(dataValue)) {
                    setQuestionIndex(prev => prev + 1);
                }
            }
        } else {
            // if it is the end of question (last index) then click to submit
            if (checkOptions()) {
                setIsLoading(true);
                handleQuestionSubmit('section_2');
            }
        }
    }

    function checkOptions(currentState) {
        const tempData = currentState;
        if (questionIndex === 1) return true;
        const questionId = questions[questionIndex - 2].id;
        if (!!dataValue?.section_2?.[questionId]) {
            return true;
        } else {
            setOptionError(tempData?.section_2[questionIndex - 1] === undefined);
            return false;
        }
    }

    function handleAnswerChange(value) {
        setChange(value);
        setOptionError(false);
    }

    function compareUserObject(currentUser, prevUser) {
        return JSON.stringify(currentUser) === JSON.stringify(prevUser);
    }

    function checkLength() {
        if (!!dataValue?.section_2) {
            return Object.keys(dataValue?.section_2).length;
        }
        return 0;
    }

    return (
        <>
            <span className="step">
                Step {questionIndex} of {questions.length + 1}
            </span>
            <EligibilityProgress
                min={0}
                max={questions.length + 1}
                now={checkLength() + 1}
                label={Math.floor(((checkLength() + 1) / (questions.length + 1)) * 100) + '%'}
            />
            {/* user info  */}
            {questionIndex <= 1 && (
                <div>
                    <CustomTextField
                        name="name"
                        type="text"
                        value={user.name}
                        customStyle="mb-0 mt-3"
                        placeholder="Name"
                        onChange={handleChange}
                        invalid={!!errors?.name}
                        invalidText={errors?.name && errors?.name[0]}
                    />
                    <CustomTextField
                        name="email"
                        value={user.email}
                        type="text"
                        placeholder="Email"
                        customStyle="mb-0 mt-3"
                        onChange={handleChange}
                        invalid={!!errors?.email}
                        invalidText={errors?.email && errors?.email[0]}
                    />
                    <CustomPhoneNumber
                        name="phone"
                        value={user.phone}
                        customStyle="mb-3 mt-3 py-2"
                        country="us"
                        onChange={(value, country) => handlePhoneChange(value, country)}
                        invalid={!!errors?.phone}
                        invalidText={errors?.phone && errors?.phone[0]}
                    />
                    <div className="birthday-container">
                        <div>
                            <label className="text-light">Birthday</label>
                        </div>
                        <div className="select-date-picker">
                            <SelectDatepicker
                                labels={selectDateOptions}
                                className={errors?.birthday ? 'errors' : ''}
                                hideLabels={true}
                                selectedDate={
                                    typeof user?.birthday !== 'object' ? new Date(user?.birthday) : user?.birthday
                                }
                                onDateChange={onDateChange}
                            />
                            {errors?.birthday && <div className="d-block invalid-feedback">{errors?.birthday[0]}</div>}
                        </div>
                    </div>
                    <div>
                        <CustomLoadingButton
                            text="Next"
                            size="extra_width"
                            isLoading={isLoading}
                            onClick={handleSubmit}
                            variant="primary"
                        ></CustomLoadingButton>
                    </div>
                </div>
            )}
            {/* question test */}
            <div>
                {questionIndex > 1 && (
                    <>
                        <EligibilityTest index={questionIndex - 2} values={change} handleChange={handleAnswerChange} />
                        {!!optionError && <span className="d-block invalid-feedback">One option is require</span>}

                        <div className="mt-2 d-flex justify-content-between justify-content-sm-start">
                            <>
                                {questionIndex > 1 && (
                                    <button
                                        className="btn btn-secondary btn-back mx-2 px-5 py-2"
                                        type="button"
                                        onClick={() => {
                                            setOptionError(false);
                                            setQuestionIndex(prev => prev - 1);
                                        }}
                                    >
                                        Back
                                    </button>
                                )}
                            </>
                            <CustomLoadingButton
                                text="Next"
                                size="extra"
                                isLoading={isLoading}
                                onClick={handleSubmit}
                                variant="primary"
                            ></CustomLoadingButton>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
