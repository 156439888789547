import React from 'react';
import { useSelector } from 'react-redux';
import { getPage } from '../../reducks/pages/selectors';
import logo from '../../assets/images/logo.png';
import { useLocation } from 'react-router';

export default function Header() {
    const selector = useSelector(state => state);
    const page = getPage(selector);
    const pathname = useLocation().pathname;

    return (
        <div className="header">
            <div className="header--wrap">
                <div className="header--logo-wrap">
                    <img src={logo} alt="" />
                </div>
                <div className="header--title-wrap">
                    {pathname === '/select-location' ? (
                        <>
                            <h4 className="title">テックアイエス<br/> 無料プログラミング体験会</h4>
                            <p className="description">ご希望の参加場所を選んでください。</p>
                        </>
                    ) : (
                        page.data && (
                            <>
                                <h4 className="title">{page.data.title}</h4>
                                <div className="description">
                                    <p dangerouslySetInnerHTML={{ __html: page.data.description }}></p>
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
