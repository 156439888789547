export const questions = [
    // question 1
    {
        id: 1,
        title: 'Look at the series: 2, 1, (1/2), (1/4), ...  What number should come next?',
        options: [
            {
                id: 1,
                name: '(1/3)',
                value: 1
            },
            {
                id: 2,
                name: '(1/8)',
                value: 2
            },
            {
                id: 3,
                name: '(2/8)',
                value: 3
            },
            {
                id: 4,
                name: '(1/16)',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 1
    },
    // question 2
    {
        id: 2,
        title: "'X' is two years older than 'Y' who is twice as old as 'Z'. If the total of the ages of 'X', 'Y' and 'Z' is 27, then how old is 'Y'?",
        options: [
            {
                id: 1,
                name: '7',
                value: 1
            },
            {
                id: 2,
                name: '8',
                value: 2
            },
            {
                id: 3,
                name: '9',
                value: 3
            },
            {
                id: 4,
                name: '10',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 4
    },
    // question 3
    {
        id: 3,
        title: 'Find X:    0.009 / X= 0.01',
        options: [
            {
                id: 1,
                name: '0.0009',
                value: 1
            },
            {
                id: 2,
                name: '0.09',
                value: 2
            },
            {
                id: 3,
                name: '0.9',
                value: 3
            },
            {
                id: 4,
                name: '9',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 3
    },
    // question 4
    {
        id: 4,
        title: 'The cube root of 0.000216 is:',
        options: [
            {
                id: 1,
                name: '0.6',
                value: 1
            },
            {
                id: 2,
                name: '0.06',
                value: 2
            },
            {
                id: 3,
                name: '77',
                value: 3
            },
            {
                id: 4,
                name: '87',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 2
    },
    // question 5
    {
        id: 5,
        title: 'Select the one which is different from the other three responses.',
        options: [
            {
                id: 1,
                name: '1',
                value: 1
            },
            {
                id: 2,
                name: '65',
                value: 2
            },
            {
                id: 3,
                name: '8',
                value: 3
            },
            {
                id: 4,
                name: '64',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 2
    },
    //question 6
    {
        id: 6,
        title: 'Mr. George is thirteenth from the top and twenty seventh from the bottom in the queue. How many persons are there in the queue?',
        options: [
            {
                id: 1,
                name: '33',
                value: 1
            },
            {
                id: 2,
                name: '37',
                value: 2
            },
            {
                id: 3,
                name: '35',
                value: 3
            },
            {
                id: 4,
                name: '39',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 4
    },
    //question 7
    {
        id: 7,
        title: `In the following questions, assuming the given statements to be true, find which of the two conclusions (from conclusions 1 and 2) is/are definitely true. Statements: A &gt; B, B ≥ C, C &lt; D, Conclusions: 1. A &gt; C, 2. A = C, choose the correct option.`,
        options: [
            {
                id: 1,
                name: 'Only 1 is true',
                value: 1
            },
            {
                id: 2,
                name: 'Only 2 is true',
                value: 2
            },
            {
                id: 3,
                name: 'Either 1 or 2 true',
                value: 3
            },
            {
                id: 4,
                name: 'Neither 1 nor 2 is true',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 1
    },
    //question 8
    {
        id: 8,
        title: 'If today is Thursday, after 132 days, what day will it be?',
        options: [
            {
                id: 1,
                name: 'Monday',
                value: 1
            },
            {
                id: 2,
                name: 'Sunday',
                value: 2
            },
            {
                id: 3,
                name: 'Wednesday',
                value: 3
            },
            {
                id: 4,
                name: 'Thursday',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 3
    },
    //question 9
    {
        id: 9,
        title: 'Which one of the following is not a prime number?',
        options: [
            {
                id: 1,
                name: '31',
                value: 1
            },
            {
                id: 2,
                name: '61',
                value: 2
            },
            {
                id: 3,
                name: '71',
                value: 3
            },
            {
                id: 4,
                name: '91',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 4
    },
    //question 10
    {
        id: 10,
        title: 'Arrange the words given below in a meaningful sequence. 1.Presentation  2.Recommendation  3. Arrival  4.Discussion  5.Introduction',
        options: [
            {
                id: 1,
                name: '5, 3, 4, 1, 2',
                value: 1
            },
            {
                id: 2,
                name: '3, 5, 4, 2, 1',
                value: 2
            },
            {
                id: 3,
                name: '3, 5, 1, 4, 2',
                value: 3
            },
            {
                id: 4,
                name: '5, 3, 1, 2, 4',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 3
    },
    //question 11
    {
        id: 11,
        title: "How many 4's are there preceded by 7 but not followed by 3?  5  9  3  2  1  7  4  2  6  9  7  4  6  1  3  2  8  7  4  1  3  8  3  2  5  6  7  4  3  9  5  8  2  0  1  8  7  4  6  3",
        button_name: 'Next',
        options: [
            {
                id: 1,
                name: 'Four',
                value: 1
            },
            {
                id: 2,
                name: 'Three',
                value: 2
            },
            {
                id: 3,
                name: 'Six',
                value: 3
            },
            {
                id: 4,
                name: 'Five',
                value: 4
            },
            {
                id: 5,
                name: 'None of the above',
                value: 5
            }
        ],
        correct: 4
    }
];
