import BaseRequest from './base-request';

class PageRequest extends BaseRequest {
    getPageSchedule(slug, params = {} ) {
        return this.performRequest(BaseRequest.METHOD_GET, `${slug}/schedules`, params);
    }

    getAdminPageSchedule(slug, params = {} ) {
        return this.performRequest(BaseRequest.METHOD_GET, `admin/${slug}/schedules`, params);
    }
}

export default new PageRequest('pages');