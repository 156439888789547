import axios from 'axios';

import BaseResponse from '../responses/base-response';

var baseURL;
if (process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    baseURL = process.env.REACT_APP_API_BASE_URL;
} else {
    baseURL = 'http://127.0.0.1:8000';
}

const http = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
});

http.interceptors.request.use(
    config => {
        config.headers.common['x-api-key'] = 'techis_booking_613415143150796296161';
        config.headers.common['x-timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// response interceptor
http.interceptors.response.use(
    httpResponse => {
        const response = new BaseResponse({ data: httpResponse.data, statusCode: httpResponse.status });
        return Promise.resolve(response.getData());
    },
    error => {
        const response = new BaseResponse({ data: error.response.data, statusCode: error.response.status });
        if (response.statusUnprocessableEntity()) {
            return Promise.reject(response.getData());
        } else if (response.statusNotFound()) {
            return Promise.reject(response.getData());
        } else if (response.statusInternalServerError()) {
            return Promise.reject(response.getData());
        }

        return Promise.reject(error);
    }
);

export default http;
