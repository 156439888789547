import EligibilityProgress from './EligibilityProgress';
import { eligibilityCheck } from './json/eligibilityCheck';
import CustomRadioForm from '../../components/Custom/CustomRadioForm';
import { useEffect, useState } from 'react';
import { getLocalStorageLog, setLocalStorageLog } from '../../utils/common';

export default function EligibilityCheck({ handleChange, handleHeader }) {
    const [questionIndex, setQuestionIndex] = useState(1);
    // eslint-disable-next-line
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const dataValue = JSON.parse(getLocalStorageLog());

    useEffect(() => {
        setData(dataValue?.section_1);
        handleHeader('Check Your Eligibility');
        // eslint-disable-next-line
    }, []);

    function handleClick(evt) {
        // onChange(evt)
        setError(false);
        const { name, value } = evt.target;
        setData(prev => {
            const tempData = {
                ...prev,
                [name]: value
            };
            setLocalStorageLog({ section_1: tempData });
            return tempData;
        });
    }

    function handleChangeQuestion(event) {
        // event.preventDefault()
        if (!!dataValue?.section_1?.[eligibilityCheck[questionIndex - 1]?.id]) {
            if (questionIndex < eligibilityCheck.length) {
                setError(false);
                setQuestionIndex(prev => prev + 1);
            } else {
                handleChange('section_1');
            }
        } else {
            setError(true);
        }
    }

    function checkLength() {
        if (!!dataValue?.section_1) {
            return Object.keys(dataValue?.section_1).length;
        }
        return 0;
    }

    return (
        <>
            <div>
                <label htmlFor="step" className="text-light">
                    Step {questionIndex}/{eligibilityCheck.length}
                </label>
                <EligibilityProgress
                    min={0}
                    max={eligibilityCheck.length}
                    label={(checkLength() / eligibilityCheck.length) * 100 + '%'}
                    now={checkLength()}
                />
                <div className="card-body-container">
                    <CustomRadioForm
                        label={eligibilityCheck[questionIndex - 1]?.title}
                        name={eligibilityCheck[questionIndex - 1]?.id}
                        checkedValue={dataValue?.section_1?.[eligibilityCheck[questionIndex - 1]?.id]}
                        options={eligibilityCheck[questionIndex - 1]?.options}
                        invalid={error}
                        required
                        onClick={e => {
                            handleClick(e);
                        }}
                    />
                </div>
            </div>
            <div className="">
                <>
                    {questionIndex > 1 && (
                        <button
                            className="btn btn-secondary btn-back mx-2 px-md-5 py-2 px-4"
                            type="button"
                            onClick={() => setQuestionIndex(prev => prev - 1)}
                        >
                            Back
                        </button>
                    )}
                </>
                <button
                    className="btn btn-primary px-md-5 py-2 text-white px-4"
                    type="button"
                    onClick={handleChangeQuestion}
                >
                    {eligibilityCheck[questionIndex - 1]?.button_name ?? 'Next'}
                </button>
            </div>
        </>
    );
}
