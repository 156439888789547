export const convertTime12To24 = (language, time) => {
    if (language === 'en') {
        // Use AM and PM for English
        return time;
    }

    // Use 24 hours clock for Japanese
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === 'PM' && hours < 12) hours = hours + 12;
    if (AMPM === 'AM' && hours === 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = '0' + sHours;
    if (minutes < 10) sMinutes = '0' + sMinutes;
    return sHours + ':' + sMinutes;
};

export const convertDate = (language, date) => {
    if (language === 'en') {
        // English Example) Sunday, March 13, 2022
        return date;
    }
    // Japanese Example) 2022年3月13日(日)
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
    };
    const now = new Date(date);
    const dateString = now.toLocaleDateString('ja-JP', options);
    return dateString;
};

export const convertWeekday = (language, date) => {
    if (language === 'en') {
        // English Example) Mon
        return new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
    }
    // Japanese Example) 月
    return new Date(date).toLocaleDateString('ja-JP', { weekday: 'short' });
};
