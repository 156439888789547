import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';

import Admin from './containers/Admin';
import ConfirmBooking from './containers/ConfirmBooking';
import Home from './containers/Home';
import SelectLocation from './containers/SelectLocation';
import Eligibility from './containers/eligibility/Index.jsx'
import ConfirmBookingTest from './containers/eligibility/ConfirmBookingEligibility.jsx'

const Router = () => {
    const { pathname } = useLocation();

    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route component={SelectLocation} path="/select-location" exact />
            <Route component={Home} path="/:pageSlug" exact />
            <Route component={Eligibility} path="/:pageSlug/eligibility-check" exact />
            <Route component={Admin} path="/admin/:pageSlug" exact />
            <Route component={ConfirmBookingTest} path="/:pageSlug/eligibility-check/booking-eligibility" exact />
            <Route component={ConfirmBooking} path="/:pageSlug/booking" exact />
        </Switch>
    );
};
export default Router;
