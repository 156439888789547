import { useHistory, useLocation, useParams } from 'react-router';
import { getLocalStorageLog, removeLocalStorageLog, setLocalStorageLog } from '../../utils/common';
import { ELIGIBILITY } from '../../utils/constant';
import { eligibilityResult } from './json/eligibilityResult';

export default function EligibilityResult({ resultType, handleClick }) {
    const history = useHistory();
    const { pageSlug } = useParams();
    const { search } = useLocation();

    function handleResult(evt) {
        const dataValue = JSON.parse(getLocalStorageLog());
        evt.preventDefault();
        if (
            resultType === ELIGIBILITY.FAILED_US_CITIZEN ||
            resultType === ELIGIBILITY.FAILED_DIPLOMA ||
            resultType === ELIGIBILITY.ELIGIBILITY_FAILED
        ) {
            removeLocalStorageLog();
            window.location.reload();
        }

        
        if (resultType === ELIGIBILITY.ELIGIBILITY_PASSED || dataValue?.eligibility_result === ELIGIBILITY.PASSED) {
            const temp = {
                ...dataValue,
                eligibility_result: ELIGIBILITY.PASSED
            };
            setLocalStorageLog(temp);
            history.push({
                pathname: `/${pageSlug}`,
                search,
                state: {
                    redirect_to: 'eligibility-check/booking-eligibility'
                }
            });
            // window.location.reload();
        }
        handleClick(eligibilityResult[resultType]?.button_name);
    }

    return (
        <div className="eligibility__wrapper">
            <div className="img">
                <img src={eligibilityResult[resultType]?.img} alt="resultImage" />
            </div>
            <p className="font-weight-bold">{eligibilityResult[resultType]?.content?.reason}</p>
            <br />
            <>
                {!!eligibilityResult[resultType]?.content?.reason_2 && (
                    <p className="font-weight-bold">{eligibilityResult[resultType]?.content?.reason_2}</p>
                )}
            </>
            {
                <div className="eligibility__result">
                    <div
                        className={
                            resultType === EligibilityResult.FAILED_US_CITIZEN || resultType === ELIGIBILITY.FAILED_DIPLOMA
                                ? 'eligibility__result-content'
                                : 'eligibility__result'
                        }
                    >
                        <p className="text-primary">{eligibilityResult[resultType]?.content?.instruction?.title}</p>
                        {/* check from local storante if the failed cause by education or citizenship */}
                        <p>{eligibilityResult[resultType]?.content.instruction?.content}</p>
                    </div>
                    <br />
                    <button type="button" onClick={handleResult} className="btn btn-primary mx-2 px-4 py-2">
                        {eligibilityResult[resultType]?.button_name}
                    </button>
                </div>
            }
        </div>
    );
}
