import React from 'react';
import timezones from '../constants/timezone';

export default function TimezonePicker(props) {
    return (
        <select {...props} className="custom-select" key={props.defaultValue}>
            {timezones.map(timezone => (
                <optgroup key={timezone.group} label={timezone.group}>
                    {timezone.zones.map(z => {
                        return (
                            <option key={z.key} value={z.key}>
                                {z.value}
                            </option>
                        );
                    })}
                </optgroup>
            ))}
        </select>
    );
}
