import passIcon from '../../../assets/images/passIcon.png';
import failIcon from '../../../assets/images/failIcon.png';

export const eligibilityResult = {
    eligibility_check_passed: {
        type: 'passed',
        button_name: 'Start Test',
        img: passIcon,
        content: {
            reason: `Congratulations! You meet the requirements to participate in the TECH I.S.’s test with your educational background and the right to work in the US.
        Try the 15-minute test.`,
            instruction: {
                title: `* You can use a calculator.`
            }
        }
    },
    failed_UScitizen: {
        type: 'failed',
        button_name: 'Back to Home',
        img: failIcon,
        content: {
            reason: `Sorry. Unfortunately, you are not eligible for our course as of now.`,
            instruction: {
                title: 'Reason',
                content: 'TECH I.S.course is eligible for US citizens or Green Card holders ONLY '
            }
        }
    },
    failed_Diploma: {
        type: 'failed',
        button_name: 'Back to Home',
        img: failIcon,
        content: {
            reason: `Sorry. Unfortunately, you are not eligible for our course as of now.`,
            instruction: {
                title: 'Reason',
                content: `Sorry, you did not pass our educational criteria! A high school diploma is a minimum requirement to enroll in our program. Apply again if you qualify.`
            }
        }
    },
    eligibility_passed: {
        type: 'passed',
        button_name: 'Check Available Slots',
        img: passIcon,
        content: {
            reason: `Congratulations! You have successfully passed our Logical + Aptitude Test to qualify and enrolled in the TECH I.S. program.`,
            reason_2: `Please book a session with our academic counselor to take the interview.`
        }
    },
    eligibility_failed: {
        type: 'failed',
        button_name: 'Back to Home',
        img: failIcon,
        content: {
            reason: `Unfortunately, you did not pass our test. Better luck next time.`
        }
    }
};
