import React from 'react';
import { useHistory } from 'react-router';

export default function LocationList({ page, referral }) {
    const history = useHistory();
    let url = page.slug;
    if (referral) {
        url += '?referral=' + referral;
    }

    return (
        <div onClick={() => history.push(url)} className="select-location--location">
            {page.title}
        </div>
    );
}
