import React from 'react';
import Router from './Router';
import './App.scss';
import Header from './components/layouts/Header';
import { useLocation } from 'react-router';

const App = () => {
    const { pathname } = useLocation();
    function checkRoute(string) {
        const match = new RegExp(string).test(pathname);
        return match;
    }

    return (
        <>
            {!checkRoute('/eligibility-check') ? (
                <Header />
            ) : checkRoute('eligibility-check/booking-eligibility') ? (
                <Header />
            ) : null}
            <div className={!checkRoute('booking') ? 'main-wrapper' : null}>
                <Router />
            </div>
        </>
    );
};

export default App;
