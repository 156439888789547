import PropTypes from 'prop-types';
import React from 'react';

const CustomRadioForm = props => {
    // unused: required
    // eslint-disable-next-line no-unused-vars
    const { options, label, name, onClick, invalid, helperText, checkedValue, required } = props;

    function handleChange() {}

    const Item = () => {
        return options.map((el, index) => (
            <div className="eligibility__item-container" key={index}>
                <div>
                    <label className="radio">
                        <input
                            type="radio"
                            name={name}
                            value={el.value}
                            checked={+checkedValue === +el.value}
                            onClick={e => {
                                onClick(e);
                            }}
                            onChange={handleChange}
                        />
                        <span></span>
                    </label>
                    <label className="mx-4 eligibility__question-item">{el.name}</label>
                </div>
            </div>
        ));
    };

    return (
        <div className="question-wrapper">
            <div className="question-title">
                <label className="text-break">
                    {label}
                    {/* {!!required && <span className="mx-3 text-muted font-weight-light">{`(Required)`}</span>} */}
                </label>
            </div>
            <Item />
            <div>{!!invalid && <span className="invalid-option">{helperText ?? 'One option is require'}</span>}</div>
        </div>
    );
};

CustomRadioForm.prototype = {
    options: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    name: PropTypes.string,
    invalid: PropTypes.bool,
    invalidText: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    selectedValue: PropTypes.string
};

CustomRadioForm.defaultProps = {
    options: []
};

export default CustomRadioForm;
