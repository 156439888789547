import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useQuery } from '../hooks/useQuery';

import { updateOrCreateBookingSlot } from '../reducks/pages/operations';
import CustomModalAdmin from './Custom/CustomModalAdmin';

export default function AdminBookingDateTime(props) {
    const { date, times, isToday, isAvailable, pageId, dayIndex } = props;
    const weekday = new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
    const day = new Date(date).toLocaleDateString('en-US', { day: '2-digit' });
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const searchQuery = useQuery();
    const key = searchQuery.get('key');

    const [data, setData] = useState({
        booked_at: '',
        page: pageId,
        units_per_slot: 0
    });

    const onClickHandler = (time, index) => {
        setIsOpen(true);
        setData(prev => {
            return {
                ...prev,
                index: index,
                booked_at: time.datetime,
                units_per_slot: time.total_available_slot,
                total_available_slot: time.total_available_slot,
                remaining_available_slot: time.remaining_available_slot
            };
        });
    };

    const onChange = e => {
        setData(prev => {
            return {
                ...prev,
                units_per_slot: +e.target.value
            };
        });
    };

    const onSubmit = () => {
        setIsLoading(true);
        dispatch(
            updateOrCreateBookingSlot({
                data: {
                    page: data.page,
                    booked_at: data.booked_at,
                    units_per_slot: data.units_per_slot
                },
                key,
                dayIndex,
                index: data.index
            })
        );
        setIsOpen(false);
        setIsLoading(false);
    };

    return (
        <>
            <CustomModalAdmin
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSubmit={onSubmit}
                onChange={onChange}
                data={data}
                isLoading={isLoading}
            />
            <div className={`schedule--items ${isToday ? 'active' : ''}`}>
                <div className="schedule--items--date">
                    <div className={`schedule--items--date--full-date ${!isAvailable ? 'disabled' : ''}`}>{date}</div>
                    {isToday ? (
                        <h6 className="schedule--items--date--badge">
                            <Badge bg="primary">TODAY</Badge>
                        </h6>
                    ) : null}
                    {!isAvailable ? (
                        <h6 className="schedule--items--date--badge only-sm">
                            <Badge bg="primary-dark">NO AVAILABILITY</Badge>
                        </h6>
                    ) : null}
                    <div className={`schedule--items--date--week-day ${!isAvailable ? 'disabled' : ''}`}>{weekday}</div>
                    <div className={`schedule--items--date--day ${!isAvailable ? 'disabled' : ''}`}>{day}</div>
                </div>
                <div className="schedule--items--time-wrapper">
                    {times.map((time, index) => {
                        return (
                            <div
                                key={`${index}-${time.start_time}`}
                                className={`schedule--items--time ${!time.is_available ? 'disabled admin' : ''} ${
                                    index === 0 ? 'active' : ''
                                }`}
                                onClick={() => onClickHandler(time, index)}
                            >
                                {time.start_time}
                                <span className="remaining-booking">
                                    T: {time.total_available_slot}, R: {time.remaining_available_slot}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

AdminBookingDateTime.propTypes = {
    date: PropTypes.string.isRequired,
    times: PropTypes.array.isRequired,
    isToday: PropTypes.bool,
    timezone: PropTypes.string
};

AdminBookingDateTime.defaultProps = {
    isAvailable: false,
    isToday: false
};
