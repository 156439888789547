import PropTypes from 'prop-types';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

export default function CustomLoadingButton(props) {
    const { text, isLoading, onClick, size, fullWidth } = props;
    const classSize = size ? `custom-button-${size}` : '';
    const classWidth = fullWidth ? `w-100` : '';

    return (
        <Button
            className={`text-white custom-button ${classSize} ${classWidth} `}
            variant="primary"
            disabled={isLoading}
            onClick={!isLoading ? onClick : null}
        >
            {isLoading ? (
                <>
                    <Spinner size="sm" animation="border" /> <span>{text}</span>
                </>
            ) : (
                text
            )}
        </Button>
    );
}

CustomLoadingButton.propTypes = {
    text: PropTypes.string,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

CustomLoadingButton.defaultProps = {
    text: 'Submit',
    isLoading: false
};
