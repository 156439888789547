import PropTypes from 'prop-types';

export default function CustomNotFound(props) {
    return <p className="h4 text-center m-3">{props.message}</p>;
}

CustomNotFound.defaultProps = {
    message: 'Not found'
};

CustomNotFound.propTypes = {
    message: PropTypes.string
};
