import { questions } from './json/eligibilityTestJson';
import CustomRadioForm from '../../components/Custom/CustomRadioForm';
import { getLocalStorageLog, setLocalStorageLog } from '../../utils/common';
import { useState, useEffect } from 'react';

export default function EligibilityTest({ index, values, handleChange }) {
    const dataValue = JSON.parse(getLocalStorageLog());
    // eslint-disable-next-line
    const [error, setError] = useState(false);
    // eslint-disable-next-line
    const [data, setData] = useState(null);
    const [currentChecked, setCurrentCheck] = useState();

    useEffect(() => {
        setData(dataValue?.section_2);
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setCurrentCheck(dataValue?.section_2?.[questions[index]?.id]);
        // eslint-disable-next-line
    }, [dataValue]);

    function handleClick(evt) {
        const { name, value } = evt.target;
        handleChange(!values);
        setError(false);
        setData(prev => {
            const tempData = {
                ...prev,
                [name]: value
            };
            setLocalStorageLog({ ...dataValue, section_2: tempData });
            return tempData;
        });
    }

    return (
        <>
            <CustomRadioForm
                label={questions[index]?.id + '. ' + questions[index]?.title}
                checkedValue={currentChecked}
                name={questions[index]?.id}
                options={questions[index]?.options}
                required
                onClick={e => {
                    handleClick(e);
                }}
            />
        </>
    );
}
