export default function CustomTimer(setting, callBack) {
    const { minute, second } = setting;
    return (
        <>
            <div>
                <span>
                    <label htmlFor="time left" className="time-left">
                        Remaining Time
                    </label>
                    <label htmlFor="Time left">
                        {minute}:{second}
                    </label>
                </span>
            </div>
        </>
    );
}
