export const GET_PAGE_DETAIL = 'GET_PAGE_DETAIL';
export const getPageDetailAction = page => {
    return {
        type: GET_PAGE_DETAIL,
        payload: { page }
    };
};

export const GET_PAGE_SCHEDULE = 'GET_PAGE_SCHEDULE';
export const getPageScheduleAction = schedules => {
    return {
        type: GET_PAGE_SCHEDULE,
        payload: { schedules }
    };
};

export const GET_ADMIN_PAGE_SCHEDULE = 'GET_ADMIN_PAGE_SCHEDULE';
export const getAdminPageScheduleAction = schedules => {
    return {
        type: GET_ADMIN_PAGE_SCHEDULE,
        payload: { schedules }
    };
};

export const UPDATE_OR_CREATE_BOOKING_SLOT = 'UPDATE_OR_CREATE_BOOKING_SLOT';
export const updateOrCreateBookingSlotAction = (schedules, dayIndex, index) => {
    return {
        type: UPDATE_OR_CREATE_BOOKING_SLOT,
        payload: { schedules, dayIndex, index}
    };
};
