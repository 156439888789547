import * as Actions from './actions';
import initialState from '../store/initialState';

export const pagesReducer = (state = initialState.page, action) => {
    switch (action.type) {
        case Actions.GET_PAGE_DETAIL:
            return {
                ...state,
                ...action.payload.page,
            };
        case Actions.GET_PAGE_SCHEDULE:
            return {
                ...state,
                ...action.payload.schedules,
            };
        case Actions.GET_ADMIN_PAGE_SCHEDULE:
            return {
                ...state,
                ...action.payload.schedules,
            };
        case Actions.UPDATE_OR_CREATE_BOOKING_SLOT:
            const data = action.payload.schedules.data
            const totalAvailableSlot = state.admin_schedules[action.payload.dayIndex].times[action.payload.index].total_available_slot
            const remainingAvailableSlot = state.admin_schedules[action.payload.dayIndex].times[action.payload.index].remaining_available_slot

            state.admin_schedules[action.payload.dayIndex].times[action.payload.index].total_available_slot = data.units_per_slot
            state.admin_schedules[action.payload.dayIndex].times[action.payload.index].remaining_available_slot = (data.units_per_slot - totalAvailableSlot) + remainingAvailableSlot
            
            return {
                ...state,
            };
        default:
            return state;
    }
};
