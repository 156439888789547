const stdTimezoneOffset = () => {
    var jan = new Date(0, 1);
    var jul = new Date(6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDaylight = () => {
    // this one is done by the backend
    var today = new Date();
    return today.getTimezoneOffset() < stdTimezoneOffset();
};

const timezonesDaylight = [
    {
        group: 'Asia',
        zones: [
            {
                key: 'GMT+9',
                value: 'Asia/Tokyo'
            },
            {
                key: 'GMT+5:30',
                value: 'Asia/Kolkata'
            }
        ]
    },
    {
        group: 'USA',
        zones: [
            {
                key: 'EDT',
                value: 'US/Eastern'
            },
            {
                key: 'CDT',
                value: 'US/Central'
            },
            {
                key: 'MDT',
                value: 'US/Mountain'
            },
            {
                key: 'PDT',
                value: 'US/Pacific'
            },
            {
                key: 'AKDT',
                value: 'US/Alaska'
            },
            {
                key: 'HDT',
                value: 'US/Hawaii'
            }
        ]
    },
    {
        group: 'Europe',
        zones: [
            {
                key: 'GMT',
                value: 'Europe/London'
            }
        ]
    }
];

const timezonesStandard = [
    {
        group: 'Asia',
        zones: [
            {
                key: 'GMT+9',
                value: 'Asia/Tokyo'
            },
            {
                key: 'GMT+5:30',
                value: 'Asia/Kolkata'
            }
        ]
    },
    {
        group: 'USA',
        zones: [
            {
                key: 'EST',
                value: 'US/Eastern'
            },
            {
                key: 'CST',
                value: 'US/Central'
            },
            {
                key: 'MST',
                value: 'US/Mountain'
            },
            {
                key: 'PST',
                value: 'US/Pacific'
            },
            {
                key: 'AKST',
                value: 'US/Alaska'
            },
            {
                key: 'HST',
                value: 'US/Hawaii'
            }
        ]
    },
    {
        group: 'Europe',
        zones: [
            {
                key: 'GMT',
                value: 'Europe/London'
            }
        ]
    }
];

let timezones = timezonesStandard;
if (isDaylight()) {
    console.log('daylight');
    timezones = timezonesDaylight;
}

export default timezones;
