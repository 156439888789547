import { useState, useEffect } from 'react';
import { getLocalStorageLog, removeLocalStorageLog, setLocalStorageLog } from '../../utils/common';
import EligibilityCheck from './EligibilityCheck';
import EligibilityResult from './EligibilityResult';
import EligibilityUserInfo from './EligibilityUserInfo';
import { questions } from './json/eligibilityTestJson';
import Timer from './Timer';
import eligibilityRequest from '../../requests/eligibility-request';
import { useHistory, useParams } from 'react-router';
import { ELIGIBILITY } from '../../utils/constant';
import Popup from 'reactjs-popup';

import logo from '../../assets/images/logo-icon-trans.png';

export default function Index() {
    const [type, setType] = useState(null);
    // eslint-disable-next-line
    const [stateLog, setStateLog] = useState(null);
    const [dynamicHeader, setDynamicHeader] = useState(null);
    const [submit, setSubmit] = useState(null);
    const [popUp, setPopUp] = useState(false);
    const dataValue = JSON.parse(getLocalStorageLog());
    const [time, setTime] = useState(null);
    const history = useHistory();
    const { pageSlug } = useParams();

    useEffect(() => {
        if (!dataValue) {
            setLocalStorageLog({ section_1: null });
            setSubmit(null);
        }
        // eslint-disable-next-line
    }, []);

    async function handleSubmit(sectionType) {
        // event.preventDefault();
        const dataValue = JSON.parse(getLocalStorageLog());
        setType(null);
        switch (sectionType) {
            case 'section_1':
                const result = calculateEligilibityCheck(dataValue);
                setType(result);
                setStateLog(dataValue);
                setStateLog(prev => {
                    const tempData = {
                        ...prev.section_1,
                        result: result
                    };
                    if (result === ELIGIBILITY.ELIGIBILITY_CHECK_PASSED) {
                        setSubmit(true);
                        setDynamicHeader('Result of Your Eligibility');
                        setLocalStorageLog({ section_1: tempData });
                        return { section_1: tempData };
                    } else {
                        setSubmit(true);
                        return { section_1: tempData };
                    }
                });
                break;
            case 'section_2':
                const resultTest = await calculateEligilibityTest();
                setDynamicHeader('Result of Logical + Aptitude Test');
                setType(resultTest);
                setSubmit(true);
                if (resultTest === ELIGIBILITY.ELIGIBILITY_PASSED) {
                    const temp = {
                        ...dataValue,
                        eligibility_result: ELIGIBILITY.PASSED
                    };
                    setLocalStorageLog(temp);
                }
                break;
            default:
                console.log('no action');
        }
    }

    function calculateEligilibityCheck(data) {
        const notUScitizen = data?.section_1[1];
        const noDiploma = data?.section_1[2];

        if (+notUScitizen === 4) {
            return ELIGIBILITY.FAILED_US_CITIZEN;
        }
        if (+noDiploma === 4) {
            return ELIGIBILITY.FAILED_DIPLOMA;
        }
        if (+notUScitizen !== 4 && +noDiploma !== 4) {
            return ELIGIBILITY.ELIGIBILITY_CHECK_PASSED;
        }
    }

    function handleUserTest() {
        const dataValue = JSON.parse(getLocalStorageLog());

        //15minute
        var dateTime = new Date(dataValue?.start_test);
        dateTime = dateTime.setMinutes(dateTime.getMinutes() + 15);
        const date1 = new Date(dateTime);
        const date2 = new Date();
        var difference = date1.getTime() - date2.getTime();
        var resultInMinutes = Math.round((difference / 60000) * 60);
        setTime(() => resultInMinutes);
    }

    async function calculateEligilibityTest() {
        const dataValue = JSON.parse(getLocalStorageLog());
        const testAnswers = dataValue?.section_2;
        const arr = [];
        //count correct question
        const tempArr = Object.keys(testAnswers);
        questions.forEach(element => {
            tempArr.forEach(item => {
                if (+item === +element.id) {
                    if (+element.correct === +testAnswers[item]) {
                        arr.push(element);
                    }
                }
            });
        });

        // call booking api to update of user is pass or fail
        if (arr.length >= 3) {
            const testLog = formatTestLog(ELIGIBILITY.PASSED_TEST, arr.length);
            const tempTestLog = JSON.stringify(testLog);
            const data = {
                session_token: dataValue?.session_token,
                online_process_step: ELIGIBILITY.PASSED_TEST,
                test_log: tempTestLog
            };

            try {
                const result = await eligibilityRequest.edit(data);
                if (result.status === 200) {
                    return ELIGIBILITY.ELIGIBILITY_PASSED;
                }
                if (result.status === 500) {
                    return ELIGIBILITY.ELIGIBILITY_FAILED;
                }
            } catch {
                console.log('Error');
            }
        } else if (arr.length < 3) {
            const testLog = formatTestLog(ELIGIBILITY.FAILED_TEST, arr.length);
            const tempTestLog = JSON.stringify(testLog);
            const data = {
                session_token: dataValue?.session_token,
                online_process_step: ELIGIBILITY.FAILED_TEST,
                test_log: tempTestLog
            };
            try {
                //set result to database then remove localstorage.
                const result = await eligibilityRequest.edit(data);
                if (result?.status === 200) {
                    setLocalStorageLog({ ...dataValue, session_token: null });
                    return ELIGIBILITY.ELIGIBILITY_FAILED;
                }
            } catch {
                setLocalStorageLog({});
                console.log('Error');
            }
        }
    }

    function handleResultClick(data) {
        if (data === 'Start Test') {
            const data = JSON.parse(getLocalStorageLog());
            setLocalStorageLog({ ...data, section_2: '' });
            setType(null);
        }
        if (data === 'Back to Home') {
            removeLocalStorageLog();
            setType(null);
            history.push({
                pathname: `/${pageSlug}/eligibility-check`
            });
        }
    }

    function formatTestLog(result, score) {
        const dataValue = JSON.parse(getLocalStorageLog());

        let questionLogObj = [];
        questions.map((el, index) => {
            const optionAnswer = el.options.filter(item => +item.id === +dataValue.section_2[el.id]);
            if (dataValue.section_2[el.id]) {
                questionLogObj.push({ Question: el.title, Answer: optionAnswer[0].name });
            }
            return true;
        });

        const logObject = {
            test_result: result,
            test_score: score,
            test_log: questionLogObj
        };

        return logObject;
    }

    function handleTimeoutPopup() {
        setPopUp(true);
    }
    function handlePopUpClick() {
        handleSubmit('section_2');
        setPopUp(false);
    }

    return (
        <>
            <div style={{ height: '52px' }}>
                <img src={logo} alt="" style={{ width: 'auto', height: 'inherit' }} />
            </div>
            <div className="eligibility__container">
                <div className="eligibility__container-sub"></div>
                <main className="eligibility__content">
                    <h1 className="my-3">{dynamicHeader}</h1>
                    <div className="mt-5 mx-3">
                        {/* enable timer when user start test */}
                        {type == null && dataValue?.section_2 !== undefined && (
                            <div>
                                {time !== null && (
                                    <Timer
                                        remaining={time}
                                        freezeTime={false}
                                        handleSubmitQuestion={handleTimeoutPopup}
                                    />
                                )}
                            </div>
                        )}
                        <div className="card">
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    {dataValue?.section_2 === undefined && !submit && (
                                        <EligibilityCheck handleChange={handleSubmit} handleHeader={setDynamicHeader} />
                                    )}
                                    {type == null && dataValue?.section_2 !== undefined && (
                                        <EligibilityUserInfo
                                            handleQuestionSubmit={handleSubmit}
                                            handleHeader={setDynamicHeader}
                                            handleUserTest={handleUserTest}
                                        />
                                    )}
                                    {/* call api to edit weather user is pass or fail  */}
                                    {type !== null && (
                                        <EligibilityResult resultType={type} handleClick={handleResultClick} />
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
            {/* popup check result */}
            <Popup open={popUp} modal closeOnDocumentClick={false}>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Logical + Aptitude Test</h5>
                        <div>
                            <p className="card-text">
                                Sorry, you’ve run out of time! Your answers were automatically submitted. Click below to
                                view your results.
                            </p>
                        </div>
                        <div>
                            <button
                                className="btn btn-primary mt-2 mx-2 px-4 py-2 text-white"
                                onClick={handlePopUpClick}
                            >
                                Click for Test Result
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    );
}
