import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { convertDate, convertWeekday, convertTime12To24 } from '../utils/translationFunc';

export default function BookingDateTime(props) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { pageSlug } = useParams();
    const { search } = useLocation();
    const { date, times, isToday, isAvailable, timezone, redirect_to } = props;
    const weekday = convertWeekday(i18n.language, date);
    const day = new Date(date).toLocaleDateString('en-US', { day: '2-digit' });
    const onSelectTime = (start_time, end_time, datetime) => {
        history.push({
            pathname: `${pageSlug}/${redirect_to}`,
            search,
            state: {
                date,
                start_time,
                end_time,
                datetime,
                timezone
            }
        });
    };

    return (
        <div className={`schedule--items ${isToday ? 'active' : ''}`}>
            <div className="schedule--items--date">
                <div className={`schedule--items--date--full-date ${!isAvailable ? 'disabled' : ''}`}>
                    {convertDate(i18n.language, date)}
                </div>
                {isToday ? (
                    <h6 className="schedule--items--date--badge">
                        <Badge bg="primary">{t('TODAY')}</Badge>
                    </h6>
                ) : null}
                {!isAvailable ? (
                    <h6 className="schedule--items--date--badge only-sm">
                        <Badge bg="primary-dark">{t('NO AVAILABILITY')}</Badge>
                    </h6>
                ) : null}
                <div className={`schedule--items--date--week-day ${!isAvailable ? 'disabled' : ''}`}>{weekday}</div>
                <div className={`schedule--items--date--day ${!isAvailable ? 'disabled' : ''}`}>{day}</div>
            </div>
            <div className="schedule--items--time-wrapper">
                {times.map((time, index) => {
                    return (
                        <div
                            key={`${index}-${time.start_time}`}
                            className={`schedule--items--time ${!time.is_available ? 'disabled' : ''} ${
                                index === 0 ? 'active' : ''
                            }`}
                            onClick={() => onSelectTime(time.start_time, time.end_time, time.datetime)}
                        >
                            {convertTime12To24(i18n.language, time.start_time)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

BookingDateTime.propTypes = {
    date: PropTypes.string.isRequired,
    times: PropTypes.array.isRequired,
    isToday: PropTypes.bool,
    timezone: PropTypes.string
};

BookingDateTime.defaultProps = {
    isAvailable: false,
    isToday: false
};
