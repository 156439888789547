import React from 'react'
import { ProgressBar } from 'react-bootstrap'

export default function EligibilityProgress({ min, max, now, label }) {
    return (
        <ProgressBar
            now={now}
            min={min}
            max={max}
            label={label}
             />
    )
}
