import CustomTimer from '../../components/Custom/CustomTimer';
import { useState, useEffect } from 'react';

export default function Timer({ remaining, freezeTime, handleSubmitQuestion }) {
    const [timeLeft, setTimeLeft] = useState(remaining);

    const second = Math.floor(timeLeft % 60);
    const minute = Math.floor((timeLeft % (1000 * 60)) / 60);

    useEffect(() => {
        
        if (timeLeft < 2 || freezeTime) {
            handleSubmitQuestion('section_2');
            return;
        }

        if (timeLeft > 1) {
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line
    }, [timeLeft]);

    return (
        <>
            {!!remaining && (
                <div className="">
                    <div>
                        <div className="timer">
                            <CustomTimer minute={minute} second={second} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
