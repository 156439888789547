const initialState = {
    page: {
        error: null,
        data: null,
        schedules: [],
        dateInfo: null,
        admin_schedules: [],
        redirect_to: null
    },
    booking_slots: {
        error: null,
        data: null
    },
};

export default initialState;
