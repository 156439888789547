export const eligibilityCheck = [
    {
        id:1,
        title: "Are you a US citizen or Green Card holder OR do you have work authorization for two years in the US? ",
        options: [
            {
                id:1,
                name: 'Yes, US Citizen',
                value: 1,
            },
            {
                id:2,
                name: 'Yes, green Card holder',
                value: 2,
            },
            {
                id:3,
                name: 'Yes, have work authorization for two years in the US.',
                value: 3,
            },
            {
                id:4,
                name: 'None of the above',
                value: 4,
            }

        ],
    },
    {
        id:2,
        title: "What is your highest educational qualification?",
        options: [
            {
                id:1,
                name: 'Master Degree or More',
                value: 1,
            },
            {
                id:2,
                name: 'Bachelor Degree',
                value: 2,
            },
            {
                id:3,
                name: 'High School Diploma',
                value: 3,
            },
            {
                id:4,
                name: 'No High School Diploma',
                value: 4,
            }
        ],
        button_name:"Check"
    },
]