import 'dotenv';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import AdminBookingDateTime from '../components/AdminBookingDateTime';
import CustomLoading from '../components/Custom/CustomLoading';
import CustomNotFound from '../components/Custom/CustomNotFound';
import TimezonePicker from '../components/TimezonePicker';
import { useQuery } from '../hooks/useQuery';
import { getAdminPageScheduleAction, getPageDetailAction } from '../reducks/pages/actions';
import { getPage } from '../reducks/pages/selectors';
import pageRequest from '../requests/page-request';
import { getDefaultTimezone, getFullTimeZone } from '../utils/common';

const Admin = () => {
    const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;
    const dispatch = useDispatch();
    const selector = useSelector(state => state);
    const page = getPage(selector);
    const [defaultTimezone, setDefaultTimezone] = useState(null);
    const [timezone, setTimezone] = useState(null);
    const { pageSlug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isAuthorize, setIsAuthorize] = useState(true);
    const searchQuery = useQuery();
    const dateInfo = page.dateInfo;

    const key = searchQuery.get('key');

    useEffect(() => {
        setIsLoading(true);
        if (!key || key !== REACT_APP_API_KEY) setIsAuthorize(false);
        pageRequest
            .find(pageSlug)
            .then(res => {
                dispatch(getPageDetailAction(res));
                setIsLoading(false);
            })
            .catch(error => {
                setIsError(true);
                console.log(error);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsLoading(true);
        if (defaultTimezone) {
            pageRequest
                .getAdminPageSchedule(pageSlug, { timezone: getFullTimeZone(defaultTimezone), key })
                .then(res => {
                    dispatch(getAdminPageScheduleAction({ admin_schedules: res.schedules, dateInfo: res.dateInfo }));
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsError(true);
                    console.log(error);
                });
        }

        // eslint-disable-next-line
    }, [defaultTimezone]);

    useEffect(() => {
        const defaultTimezone = getDefaultTimezone(page.data ? page.data.country : null);
        timezone ? setDefaultTimezone(timezone) : setDefaultTimezone(defaultTimezone.key);
    }, [timezone, page]);

    return (
        <>
            {isAuthorize ? (
                <>
                    <div className="header-container">
                        <div className="timezone">
                            <TimezonePicker
                                defaultValue={defaultTimezone}
                                onChange={e => {
                                    setTimezone(e.target.value);
                                }}
                            />
                        </div>
                        {dateInfo ? (
                            <div className="date-info">
                                <span>{`${dateInfo.startMonth} ${
                                    dateInfo.startYear !== dateInfo.endYear ? dateInfo.startYear : ''
                                } ${dateInfo.startMonth !== dateInfo.endMonth ? '- ' + dateInfo.endMonth : ''} ${
                                    dateInfo.endYear
                                }`}</span>
                            </div>
                        ) : null}
                    </div>
                    <div className="schedule justify-content-center">
                        {isError ? (
                            <CustomNotFound message="Page not found." />
                        ) : isLoading ? (
                            <CustomLoading />
                        ) : page && page.admin_schedules ? (
                            page.admin_schedules.map((s, index) => {
                                return (
                                    <AdminBookingDateTime
                                        key={s.date}
                                        date={s.date}
                                        times={s.times}
                                        pageId={page.data.id}
                                        isToday={index === 0}
                                        isAvailable={s.is_available_day}
                                        timezone={defaultTimezone}
                                        dayIndex={index}
                                    />
                                );
                            })
                        ) : null}
                    </div>
                </>
            ) : (
                <CustomNotFound message="Permission Denied." />
            )}
        </>
    );
};

export default Admin;
