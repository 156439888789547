import React from 'react';

export default function CustomIconButton(props) {
    const { text } = props;
    return (
        <button {...props} className="button">
            <span className="icon icon-back"></span>
            {text}
        </button>
    );
}

CustomIconButton.propTypes = {};

CustomIconButton.defaultProps = {
    text: ''
};
