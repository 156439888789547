import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CustomLoadingButton from './CustomLoadingButton';

import CustomTextField from './CustomTextField';

export default function CustomModalAdmin(props) {
    const { isOpen, setIsOpen, onChange, onSubmit, data, isLoading } = props;
    const handleClose = () => setIsOpen(false);
    const [isCenter, setIsCenter] = useState(false);


      useEffect(() => {
		window.addEventListener("resize", () => {
            window.matchMedia("(max-width: 599px)").matches ? setIsCenter(true) : setIsCenter(false)
        });
		// eslint-disable-next-line
	}, []);

    return (
        <>
            <Modal show={isOpen} onHide={handleClose} centered={isCenter}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Booking Availability Slot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Total Booking Slot: {data.total_available_slot}</span>
                    <br></br>
                    <span>Remaining Booking Slot: {data.remaining_available_slot}</span>
                    <form className="mt-3">
                        <CustomTextField
                            label="Unit Per Slot"
                            min={0}
                            name="units_per_slot"
                            onChange={onChange}
                            required
                            type="number"
                            value={data.units_per_slot}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <CustomLoadingButton
                        text="Update"
                        isLoading={isLoading}
                        onClick={onSubmit}
                        variant="primary"
                    >
                        Update
                    </CustomLoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}

CustomModalAdmin.defaultProps = {
    isOpen: false
};

CustomModalAdmin.propTypes = {
    isOpen: PropTypes.bool.isRequired
};
