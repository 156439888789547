import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';


import timezoneIcon from '../../assets/images/timezone.svg';
import CustomErrorMessage from '../../components/Custom/CustomErrorMessage';
import CustomIconButton from '../../components/Custom/CustomIconButton';
import CustomLoadingButton from '../../components/Custom/CustomLoadingButton';
import { useQuery } from '../../hooks/useQuery';
import { getPage } from '../../reducks/pages/selectors';
import bookingRequest from '../../requests/booking-request';
import eligibilityRequest from '../../requests/eligibility-request';
import { getFullTimeZone, getLocalStorageLog, removeLocalStorageLog } from '../../utils/common';
import { convertDate, convertTime12To24 } from '../../utils/translationFunc';

export default function ConfirmBooking() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { date, start_time, end_time, timezone, datetime } = location.state || {};
    const selector = useSelector(state => state);
    const page = getPage(selector);
    const { pageSlug } = useParams();
    const searchQueries = useQuery();
    const [isLoading, setIsLoading] = useState(false);
    const dataValue = JSON.parse(getLocalStorageLog());

    useEffect(() => {
        if (!page.data) {
            history.push(`/${pageSlug}/eligibility-check/`);
        } else {
            setData(prev => ({
                ...prev,
                page: page.data.id
            }));
        }
        // eslint-disable-next-line
    }, []);

    const [data, setData] = useState({
        name: dataValue?.user_info?.name,
        email: dataValue?.user_info?.email,
        phone: dataValue?.user_info?.phone,
        page: null,
        agreed_privacy: false,
        source:'booking_system',
        timezone: getFullTimeZone(timezone),
        type_of_booking: 'eligibility',
        booked_at: datetime,
        referral: searchQueries.get('referral')
    });
    const [errors, setErrors] = useState([]);

    const onSubmit = e => {
        e.preventDefault();
        if (page.data.language !== 'jp' && data.phone && !isValidPhoneNumber(data.phone)) {
            setErrors(prev => {
                return { ...prev, phone: [`Invalid Phone Number.`] };
            });
            return;
        }
        if (page.data.language === 'jp' && !data.agreed_privacy) {
            setErrors(prev => {
                return { ...prev, agreed_privacy: [`プライバシーポリシーに同意してください。`] };
            });
            return;
        }
        setErrors(prev => {
            return { ...prev, phone: null };
        });
        setIsLoading(true);
        //call api to update online_process_step
        const dataValue = JSON.parse(getLocalStorageLog());
        const dataTemp = {
            session_token: dataValue?.session_token,
            online_process_step: 'booked',
            ...data
        };

        eligibilityRequest.edit(dataTemp);

        bookingRequest
            .store(data)
            .then(response => {
                removeLocalStorageLog();
                let url = page.data.redirect_to + '?booking_id=' + response.id;
                window.location.href = url;
            })
            .catch(err => {
                if (err.response) {
                    setErrors(err.response.data);
                    setIsLoading(false);
                }
            });
    };

    return (
        <div className="booking">
            <div className="booking--header">
                <div className="booking--header--left">
                    <CustomIconButton onClick={() => history.goBack()} />
                </div>
                <span>{t('Booking Form')}</span>
            </div>
            <main className="booking--body">
                <form onSubmit={onSubmit} className="booking-form">
                    <div className="booking-info">
                        <p className="booking-info--date">{convertDate(i18n.language, date)}</p>
                        <p className="booking-info--time">
                            {convertTime12To24(i18n.language, start_time)} -{' '}
                            {convertTime12To24(i18n.language, end_time)}
                        </p>
                        <div className={`booking-info--timezone ${i18n.language === 'jp' ? 'hide' : ''}`}>
                            <img src={timezoneIcon} alt="" />
                            {getFullTimeZone(timezone)}
                        </div>
                    </div>
                    {!!errors.error && <CustomErrorMessage message={errors.error && errors.error} />}
                    <CustomLoadingButton
                        fullWidth
                        isLoading={isLoading}
                        text={t('Confirm Booking')}
                        onClick={onSubmit}
                    />
                </form>
            </main>
        </div>
    );
}
