import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import timezoneIcon from '../assets/images/timezone.svg';
import CustomErrorMessage from '../components/Custom/CustomErrorMessage';
import CustomIconButton from '../components/Custom/CustomIconButton';
import CustomLoadingButton from '../components/Custom/CustomLoadingButton';
import CustomPhoneNumber from '../components/Custom/CustomPhoneNumber';
import CustomAgreePrivacyCheckBox from '../components/Custom/CustomAgreePrivacyCheckBox';
import CustomTextField from '../components/Custom/CustomTextField';
import { useQuery } from '../hooks/useQuery';
import { getPage } from '../reducks/pages/selectors';
import bookingRequest from '../requests/booking-request';
import { getFullTimeZone } from '../utils/common';
import { convertDate, convertTime12To24 } from '../utils/translationFunc';

export default function ConfirmBooking() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { date, start_time, end_time, timezone, datetime } = location.state || {};
    const selector = useSelector(state => state);
    const page = getPage(selector);
    const { pageSlug } = useParams();
    const searchQueries = useQuery();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (!page.data) {
            history.push(`/${pageSlug}/`);
        } else {
            setData(prev => ({
                ...prev,
                page: page.data.id
            }));
        }

        // eslint-disable-next-line
    }, []);

    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        page: null,
        agreed_privacy: false,
        timezone: getFullTimeZone(timezone),
        source: 'booking_system',
        booked_at: datetime,
        referral: searchQueries.get('referral')
    });
    const [errors, setErrors] = useState([]);

    const onChange = e => {
        const { name, value } = e.target;
        setData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const onChangeCheckBox = e => {
        const { name, checked } = e.target;
        setData(prev => ({
            ...prev,
            [name]: checked
        }));
    };


    const onSubmit = e => {
        e.preventDefault();
        if (page.data.language !== 'jp' && data.phone && !isValidPhoneNumber(data.phone)) {
            setErrors(prev => {
                return { ...prev, phone: [`Invalid Phone Number.`] };
            });
            return;
        }
        if (page.data.language === 'jp' && !data.agreed_privacy) {
            setErrors(prev => {
                return { ...prev, agreed_privacy: [`プライバシーポリシーに同意してください。`] };
            });
            return;
        }
        setErrors(prev => {
            return { ...prev, phone: null };
        });
        setIsLoading(true);
        bookingRequest
            .store(data)
            .then(response => {
               
                    const url = page.data.redirect_to + '?booking_id=' + response.id;
                    window.location.href = url;
                
            })
            .catch(err => {
                if (err.response) {
                    setErrors(err.response.data);
                    setIsLoading(false);
                }
            });
    };

    return (
        <>
            <div className="booking">
                <div className="booking--header">
                    <div className="booking--header--left">
                        <CustomIconButton onClick={() => history.goBack()} />
                    </div>
                    <span>{t('Booking Form')}</span>
                </div>
                <main className="booking--body">
                    <form onSubmit={onSubmit} className="booking-form">
                        <div className="booking-info">
                            <p className="booking-info--date">{convertDate(i18n.language, date)}</p>
                            <p className="booking-info--time">
                                {convertTime12To24(i18n.language, start_time)} -{' '}
                                {convertTime12To24(i18n.language, end_time)}
                            </p>
                            <div className={`booking-info--timezone ${i18n.language === 'jp' ? 'hide' : ''}`}>
                                <img src={timezoneIcon} alt="" />
                                {getFullTimeZone(timezone)}
                            </div>
                        </div>

                        <CustomTextField
                            name="name"
                            label={t('Name')}
                            required
                            invalid={!!errors.name}
                            invalidText={errors.name && errors.name[0]}
                            onChange={onChange}
                        />
                        <CustomTextField
                            name="email"
                            label={t('Email')}
                            required
                            invalid={!!errors.email}
                            invalidText={errors.email && errors.email[0]}
                            onChange={onChange}
                        />
                        {page.data && page.data.language === 'jp' ? 
                            <CustomTextField
                                name="phone"
                                label={t('Phone')}
                                required
                                invalid={!!errors.phone}
                                invalidText={errors.phone && errors.phone[0]}
                                onChange={onChange}
                            />
                         : <CustomPhoneNumber
                                name="phone"
                                label={t('Phone')}
                                required
                                invalid={!!errors.phone}
                                invalidText={errors.phone && errors.phone[0]}
                                country={page.data ? page.data.country : 'us'}
                                onChange={phone => {
                                    setData(prev => ({ ...prev, phone }));
                                }}
                            />
                        }

                        {page.data && page.data.language === 'jp' && (
                            <CustomAgreePrivacyCheckBox
                                name="agreed_privacy"
                                invalid={!!errors.agreed_privacy}
                                invalidText={errors.agreed_privacy && errors.agreed_privacy[0]}
                                onChangeCheckBox={onChangeCheckBox}
                            />
                        )}

                        {!!errors.error && <CustomErrorMessage message={errors.error && errors.error} />}
                        <CustomLoadingButton
                            fullWidth
                            isLoading={isLoading}
                            text={t('Confirm Booking')}
                            onClick={onSubmit}
                        />
                    </form>
                </main>
            </div>
        </>
    );
}
