import timezones, { isDaylight } from '../constants/timezone';

export const getDefaultTimezone = country => {
    const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZone = new Intl.DateTimeFormat('en-us', { timeZoneName: 'short', timeZone: currentZone })
        .formatToParts(new Date())
        .find(part => part.type === 'timeZoneName').value;

    const isInAvailableZone = timezones.some(group => group.zones.find(z => z.key === timeZone));

    if (isInAvailableZone) {
        return {
            key: timeZone,
            value: currentZone
        };
    }

    if (country === 'usa') {
        return {
            key: isDaylight() ? 'PDT' : 'PST',
            value: 'US/Pacific'
        };
    }

    return {
        key: 'GMT+9',
        value: 'Asia/Tokyo'
    };
};

export const getFullTimeZone = shortcut => {
    let response = '';
    timezones.forEach(group => {
        const found = group.zones.find(z => z.key === shortcut);
        if (found) response = found.value;
    });

    return response;
};

export const getCurrentTimezone = () => {
    const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZone = new Intl.DateTimeFormat('en-us', { timeZoneName: 'long', timeZone: currentZone })
        .formatToParts(new Date())
        .find(part => part.type === 'timeZoneName')
        .value.match(/[A-Z]/g)
        .join('');
    return timeZone;
};

export const AddMinute = (dt, minutes) => {
    return new Date(dt.getTime() + minutes * 60000);
};

export const capitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const setLocalStorageLog = data => {
    if (!data?.created_at) {
        data = {
            ...data,
            created_at: new Date()
        };
    }
    localStorage.setItem('X-TECHIS-DATA', JSON.stringify(data));
};

export const getLocalStorageLog = name => {
    try {
        const tempData = localStorage.getItem('X-TECHIS-DATA');
        var tempCreated_at = JSON.parse(tempData);

        var diff = Math.abs(new Date(tempCreated_at?.created_at) - new Date());
        var minutes = Math.floor(diff / 1000 / 60);
        if (minutes > 30 || isNaN(minutes)) {
            removeLocalStorageLog();
        }
        return tempData;
    } catch {
        removeLocalStorageLog();
        setLocalStorageLog({ section_1: null })
        return localStorage.getItem('X-TECHIS-DATA');
    }
};

export const removeLocalStorageLog = name => {
    return localStorage.removeItem('X-TECHIS-DATA');
};
