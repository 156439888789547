import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function CustomTextField(props) {
    const { t } = useTranslation();
    const { name, label, placeholder, required, invalid, invalidText, onChange, min, type, value, customStyle } = props;
    return (
        <Form.Group className={customStyle ?? 'mb-3'}>
            {!!label && (
                <>
                    <Form.Label>{label}</Form.Label>{' '}
                    {required ? <span className="text-muted">({t('Required')})</span> : null}
                </>
            )}
            <Form.Control
                name={name}
                className="custom-input"
                type={type}
                min={min}
                value={value}
                placeholder={placeholder}
                required={required}
                onChange={onChange}
                isInvalid={invalid}
            />
            <Form.Control.Feedback type="invalid">{invalidText}</Form.Control.Feedback>
        </Form.Group>
    );
}

CustomTextField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    invalidText: PropTypes.string,
    customStyle: PropTypes.string
};

CustomTextField.defaultProps = {
    invalid: false,
    required: false,
    type: 'text'
};
