import PropTypes from 'prop-types';

export default function CustomErrorMessage(props) {
    return <p className="error-message">{props.message}</p>;
}

CustomErrorMessage.defaultProps = {
    message: 'Error'
};

CustomErrorMessage.propTypes = {
    message: PropTypes.string
};
